import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../contex/AuthContex";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Box, Grid, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import Divider from "@mui/material/Divider";

import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useDropzone } from "react-dropzone";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CreateArticlePage = () => {
  let [article, setArticle] = useState([]);
  let [body, setBody] = useState("");
  let [images, setImages] = useState([]);
  let [galleries, setGalleries] = useState([]);
  let [articleTypes, setArticleTypes] = useState([]);
  let [chosenArticleType, setChosenArticleType] = useState("");
  let [chosenGallery, setChosenGallery] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  let { authTokens } = useContext(AuthContext);

  useEffect(() => {
    get_galleries();
    get_article_types();
    window.scrollTo(0, 0);
  }, []);

  const create_article = async () => {
    var formData = new FormData();
    images.forEach((image) => formData.append("images", image));
    formData.append("title", article.title);
    formData.append("body", body);
    formData.append("type", chosenArticleType);
    if (article.titleImage) {
      formData.append("titleImage", article.titleImage);
    }
    if (chosenGallery !== "") {
      formData.append("gallery", chosenGallery);
    }
    await fetch(`https://api.ao-litija.si/api/articles`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: formData,
    });
    navigate("/");
  };

  const get_galleries = async () => {
    let response = await fetch(`https://api.ao-litija.si/api/galleries`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    let data = await response.json();
    setGalleries(data);
  };

  const get_article_types = async () => {
    let response = await fetch(`https://api.ao-litija.si/api/type_choices`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    let data = await response.json();
    setArticleTypes(data);
    setArticle(...article, { type: { value: data[0], label: data[0] } });
  };

  const onDrop = (acceptedImages) => {
    setImages([...images, ...acceptedImages]);
  };

  const removeImage = (index) => {
    setImages(images.filter((image, i) => i !== index));
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  var options = galleries.map((gallery) => ({
    value: gallery.id,
    label: gallery.title,
  }));

  options.unshift({
    value: null,
    label: "None",
  });

  // Function to handle the file selection
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setArticle({ ...article, titleImage: event.target.files[0] });
    if (file) {
      // Set the selected image in state
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  return (
    <Box display={"flex"} justifyContent={"center"} sx={{ minHeight: "87vh"}}>
      <Card
        sx={{ maxWidth: "800px", margin: "16px", backgroundColor: "#e6e3e3" }}
      >
        <CardContent>
          <Box sx={{ width: "1000px" }}></Box>
          <Stack gap={2}>
            <Grid container alignItems={"center"} gap={1}>
              <Grid
                item
                xs={12}
                alignItems={"center"}
                justifyContent={"center"}
                display={"flex"}
              >
                Nova Objava
              </Grid>
            </Grid>
            <Grid container alignItems={"center"} gap={1}>
              <Grid item xs={6}>
                <Typography>Naslov:</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  placeholder="Naslov"
                  label="Obvezno polje"
                  size="small"
                  onChange={(e) =>
                    setArticle({ ...article, title: e.target.value })
                  }
                />
              </Grid>
            </Grid>
            <Grid container alignItems={"center"} gap={1}>
              <Grid item xs={6}>
                <Typography>Vsebina:</Typography>
              </Grid>
              <Grid item xs={12}>
                <ReactQuill
                  placeholder="Obvezno polje*"
                  theme="snow"
                  value={body}
                  onChange={setBody}
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      [{ list: "ordered" }, { list: "bullet" }],
                    ],
                  }}
                />
              </Grid>
            </Grid>
            {articleTypes && (
              <Grid container alignItems={"center"} gap={1}>
                <Grid item xs={6}>
                  <Typography>Tip Objave:</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Obvezno polje*
                    </InputLabel>
                    <Select
                      value={chosenArticleType}
                      label="Obvezno polje*"
                      onChange={(e) => setChosenArticleType(e.target.value)}
                    >
                      {articleTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <Divider
              color={"black"}
              flexItem
              variant="middle"
              sx={{ borderBottomWidth: 2 }}
            />
            <Grid container alignItems={"center"} gap={1}>
              <Grid item xs={6}>
                <Typography>Pripni Galerijo:</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Opcijsko polje
                  </InputLabel>
                  <Select
                    label="Opcijsko polje"
                    value={chosenGallery}
                    onChange={(e) => setChosenGallery(e.target.value)}
                  >
                    {galleries.map((gallery) => (
                      <MenuItem key={gallery.id} value={gallery.id}>
                        {gallery.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {selectedImage === null ? (
              <div>
                <Input
                  accept="image/*"
                  id="image-input"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(event) => (
                    handleImageChange(event),
                    setArticle({
                      ...article,
                      titleImage: event.target.files[0],
                    })
                  )}
                />
                <label htmlFor="image-input">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    Naloži Naslovno sliko
                  </Button>
                </label>
              </div>
            ) : (
              <div>
                <img src={selectedImage} alt="Selected" width="200" />
                <Button
                  sx={{ marginLeft: "20px" }}
                  color="error"
                  variant="outlined"
                  onClick={() => (
                    setSelectedImage(null),
                    setArticle({
                      ...article,
                      titleImage: null,
                    })
                  )}
                >
                  Odstrani sliko
                </Button>
              </div>
            )}
            <div
              {...getRootProps()}
              style={{
                cursor: "pointer",
                padding: "10px",
                border: "1px dashed #ccc",
              }}
            >
              <input {...getInputProps()} />
              <p>Povleci slike ali klikni, da izbereš slike</p>
            </div>
            {images.length > 0 && (
              <div style={{ marginTop: "10px" }}>
                {images.map((file, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <span>{file.name}</span>
                    <Button
                      sx={{ marginLeft: "20px" }}
                      color="error"
                      variant="outlined"
                      component="span"
                      onClick={() => removeImage(index)}
                    >
                      Odstrani sliko
                    </Button>
                  </div>
                ))}
              </div>
            )}
            <Button
              variant="contained"
              component="span"
              onClick={() => create_article()}
            >
              Ustvari objavo
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CreateArticlePage;
