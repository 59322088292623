import React, { useContext, useState, useEffect } from "react";

import AuthContext from "../contex/AuthContex";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";

import { useDropzone } from "react-dropzone";

function UpdateForumPostModal({ id, title, body, onUpdate, onImageUpdate }) {
  let [post, setPost] = useState({body: body, title: title});

  let [images, setImages] = useState([]);
  let [addImages, setAddImages] = useState([]);

  const [open, setOpen] = useState(false);

  let { authTokens } = useContext(AuthContext);

  useEffect(() => {
    get_forumPostImages();
  }, []);

  const update_forumPost = async (id) => {
    await fetch(`https://api.ao-litija.si/api/forum/${id}`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify(post),
    });
    onUpdate();
  };

  const delete_images = async (id) => {
    await fetch(`https://api.ao-litija.si/api/forum_images/delete`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({ ids: [id] }),
    });
    get_forumPostImages();
  };

  const get_forumPostImages = async () => {
    let response = await fetch(
      `https://api.ao-litija.si/api/forum_images?forumPost=${id}`
    );
    let data = await response.json();
    setImages(data);
  };

  const create_forumPostImages = async () => {
    var formData = new FormData();

    addImages.forEach((image) => formData.append("images", image))
    formData.append("forumPost_id", id);

    await fetch(`https://api.ao-litija.si/api/forum_images`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: formData,
    });
    setAddImages([]);
    get_forumPostImages();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDrop = (acceptedImages) => {
    setAddImages([...addImages, ...acceptedImages]);
  };

  const removeImage = (index) => {
    setAddImages(addImages.filter((image, i) => i !== index));
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div>
      <div onClick={handleClickOpen}>
        <EditIcon sx={{ marginRight: "6px" }} color="primary" />
        Spremeni
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ marginTop: "5px" }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Spremeni objavo</DialogTitle>
        <DialogContent>
          <Stack direction={"column"} gap={2}>
          <TextField
              sx={{ marginTop: "5px" }}
              fullWidth
              required
              placeholder="Naslov"
              label="Obvezno polje"
              size="small"
              defaultValue={post.title}
              onChange={(e) => setPost({...post, title: e.target.value })}
            />
            <TextField
              sx={{ marginTop: "5px" }}
              fullWidth
              required
              placeholder="Vsebina"
              label="Obvezno polje"
              multiline
              size="small"
              minRows={5}
              defaultValue={post.body}
              onChange={(e) => setPost({...post, body: e.target.value })}
            />
            {images &&
              images.map((image) => (
                <div key={image.id}>
                  <img src={image.image} alt="Selected" width="200" />
                  <Button
                    sx={{ marginLeft: "20px" }}
                    color="error"
                    variant="outlined"
                    onClick={() => {
                      delete_images(image.id);
                    }}
                  >
                    Odstrani sliko
                  </Button>
                </div>
              ))}
            <div
              {...getRootProps()}
              style={{
                cursor: "pointer",
                padding: "10px",
                border: "1px dashed #ccc",
              }}
            >
              <input {...getInputProps()} />
              <p>Drag and drop images here, or click to select images</p>
            </div>
            {addImages.length > 0 && (
              <div style={{ marginTop: "10px" }}>
                {addImages.map((file, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <span>{file.name}</span>
                    <Button
                      sx={{ marginLeft: "20px" }}
                      color="error"
                      variant="outlined"
                      component="span"
                      onClick={() => removeImage(index)}
                    >
                      Odstrani sliko
                    </Button>
                  </div>
                ))}
              </div>
            )}
            {addImages.length > 0 && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  create_forumPostImages();
                }}
              >
                Dodaj Slike
              </Button>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => {
              handleClose();
              onImageUpdate();
            }}
            color="error"
          >
            Prekliči
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              update_forumPost(id);
              onImageUpdate();
              handleClose();
            }}
          >
            Spremeni
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UpdateForumPostModal;
