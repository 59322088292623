import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../contex/AuthContex";

import CommentsOffCanvas from "./CommentsOffCanvas";
import UpdateForumPostModal from "../components/UpdateForumPostModal";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CommentIcon from "@mui/icons-material/Comment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DateFormater from "../utils/DateFunctions";
import ImageGallery from "react-image-gallery";
import { Box } from "@mui/material";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import "react-image-gallery/styles/css/image-gallery.css";

import defaultImage from "../assets/no_profile.jpg";

function ForumPost({
  title,
  body,
  created,
  author,
  forum_post_id,
  onChange,
  username,
  comments,
  userId,
}) {
  let { authTokens, user } = useContext(AuthContext);
  let [forumPostImages, setForumPostImages] = useState([]);
  let [profileImage, setProfileImage] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    get_forumPostImages();
    get_profile();
  }, []);

  const delete_forumPost = async (forum_post_id) => {
    await fetch(`https://api.ao-litija.si/api/forum/${forum_post_id}`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    onChange();
  };

  const get_forumPostImages = async () => {
    let response = await fetch(
      `https://api.ao-litija.si/api/forum_images?forumPost=${forum_post_id}`
    );
    let data = await response.json();
    var displayedImages = [];
    for (let index in data) {
      displayedImages.push({
        original: data[index].image,
        thumbnail: data[index].image,
        originalHeight: "200px",
        thumbnailHeight: "30px",
        thumbnailWidth: "30px",
      });
    }
    setForumPostImages(displayedImages);
  };

  const get_profile = async () => {
    let response = await fetch(
      `https://api.ao-litija.si/api/profile/${userId}?userId=${userId}`
    );
    let data = await response.json();
    if (data.image !== null) {
      setProfileImage(data);
    } else {
      setProfileImage(null);
    }
  };

  return (
    <Card sx={{ maxWidth: 650 }}>
      <CardHeader
        avatar={
          <Avatar
            alt="Remy Sharp"
            src={profileImage !== null ? profileImage.image : defaultImage}
          />
        }
        action={
          username === user.username ? (
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem>
                  <UpdateForumPostModal
                    id={forum_post_id}
                    title={title}
                    body={body}
                    onUpdate={onChange}
                    onImageUpdate={get_forumPostImages}
                  />
                </MenuItem>
                <MenuItem onClick={() => delete_forumPost(forum_post_id)}>
                  <DeleteIcon sx={{ marginRight: "6px" }} color="error" />
                  Izbriši
                </MenuItem>
              </Menu>
            </div>
          ) : null
        }
        title={author}
        subheader={created}
        sx={{ backgroundColor: "#e6e3e3" }}
      />
      <CardContent sx={{ paddingX: "80px" }}>
        {title && (
          <Typography variant="h5" color="text.secondary">
            {title}
          </Typography>
        )}
        <ImageGallery
          items={forumPostImages}
          showThumbnails={forumPostImages.length < 2 ? false : true}
          thumbnailPosition={"bottom"}
          showNav={true}
          showPlayButton={false}
          showFullscreenButton={false}
        />
        <Typography variant="body2" color="text.secondary">
          {body}
        </Typography>
      </CardContent>
      <CardActions disableSpacing sx={{ backgroundColor: "#e6e3e3" }}>
        <CommentsOffCanvas
          forum_post_id={forum_post_id}
          num_comments={comments}
        />
      </CardActions>
    </Card>
  );
}

export default ForumPost;
