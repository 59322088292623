import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../contex/AuthContex";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Input from "@mui/material/Input";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function CreateGalleryModal({ onAdd }) {
  let [createGallery, setCreateGallery] = useState({
    title: null,
    titleImage: null,
  });
  const [open, setOpen] = useState(false);
  let { authTokens } = useContext(AuthContext);
  const [selectedImage, setSelectedImage] = useState(null);

  const uploadGallery = async () => {
    var formData = new FormData();
    formData.append("title", createGallery.title);
    formData.append("titleImage", createGallery.titleImage);
    await fetch(`https://api.ao-litija.si/api/galleries`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: formData,
    });
    onAdd();
    setCreateGallery([]);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Set the selected image in state
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedImage(null);
    setCreateGallery({
      title: null,
      titleImage: null,
    });
    setOpen(false);
  };

  return (
    <div>
      <Button
        color="inherit"
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          borderRadius: 50,
          backgroundColor: "#1f4154",
          color: "#c7c5c5",
          marginRight: "20px",
          fontSize: "10px",
        }}
      >
        Ustvari Galerijo
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ marginTop: "5px" }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Ustvari Galerijo</DialogTitle>
        <DialogContent>
          <Stack direction={"column"} gap={2}>
            <TextField
              sx={{ marginTop: "5px" }}
              fullWidth
              required
              placeholder="Vsebina"
              label="Obvezno polje"
              size="small"
              onChange={(e) =>
                setCreateGallery({ ...createGallery, title: e.target.value })
              }
            />
            {selectedImage === null ? (
              <div>
                <Input
                  accept="image/*"
                  id="image-input"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => (
                    setCreateGallery({
                      ...createGallery,
                      titleImage: e.target.files[0],
                    }),
                    handleImageChange(e)
                  )}
                />
                <label htmlFor="image-input">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    Naloži Naslovno sliko
                  </Button>
                </label>
              </div>
            ) : (
              <div>
                <img src={selectedImage} alt="Selected" width="200" />
                <Button
                  sx={{ marginLeft: "20px" }}
                  color="error"
                  variant="outlined"
                  onClick={() => (
                    setSelectedImage(null),
                    setCreateGallery({
                      ...createGallery,
                      titleImage: null,
                    })
                  )}
                >
                  Odstrani sliko
                </Button>
              </div>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button onClick={handleClose} color="error">
            Prekliči
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              uploadGallery();
              handleClose();
            }}
          >
            Objavi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateGalleryModal;
