import React, { useContext, useState } from "react";
import AuthContext from "../contex/AuthContex";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Input from "@mui/material/Input";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { useDropzone } from "react-dropzone";

function UpdateGalleryModal({ id, galleryImages, onChange, title }) {
  let [addImages, setAddImages] = useState([]);
  const [deleteImages, setDeleteImages] = useState([]);
  const [imagesDescriptions, setImagesDescriptions] = useState([]);
  const [changeTitle, setChangeTitle] = useState([]);

  const [open, setOpen] = useState(false);
  let { authTokens } = useContext(AuthContext);

  const uploadFiles = async () => {
    var formData = new FormData();
    addImages.forEach((file) => formData.append("images", file.image));
    formData.append("gallery_id", id);
    await fetch(`https://api.ao-litija.si/api/gallery_images`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: formData,
    });
    onChange();
    setDeleteImages([]);
  };

  const delete_images = async () => {
    await fetch(`https://api.ao-litija.si/api/gallery_images/delete`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({ ids: deleteImages }),
    });
    onChange();
  };

  const update_image = async () => {
    await fetch(`https://api.ao-litija.si/api/gallery_images/delete`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({ descriptions: imagesDescriptions }),
    });
    onChange();
  };

  const update_title = async () => {
    await fetch(`https://api.ao-litija.si/api/galleries/${id}`, {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({ title: changeTitle }),
    });
    onChange()
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setAddImages([]);
    setOpen(false);
  };

  const handleCheck = (e, id) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setDeleteImages([...deleteImages, id]);
    } else {
      setDeleteImages(deleteImages.filter((checkedId) => checkedId !== id));
    }
  };

  const handleDescriptionChange = (e, id) => {
    var descriptionsWithoutCurrent = imagesDescriptions.filter(
      (current) => current.id !== id
    );

    setImagesDescriptions([
      ...descriptionsWithoutCurrent,
      { id: id, description: e.target.value },
    ]);
  };

  const onDrop = (acceptedImages) => {
    var addedImages = []
    acceptedImages.forEach((e) => addedImages.push({image: e, description: null}))
    setAddImages([...addImages, ...addedImages]);
  };

  const removeImage = (index) => {
    setAddImages(addImages.filter((image, i) => i !== index));
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div>
      <Button
        sx={{ marginY: "20px" }}
        variant="contained"
        color="primary"
        fullWidth={true}
        onClick={handleClickOpen}
      >
        Uredi Galerijo
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ marginTop: "5px" }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogContent>
          <Stack direction={"column"} gap={2}>
          <Typography variant="h6">
              Speremni Naslov Galerije:
            </Typography>
          <TextField
              sx={{ marginTop: "5px" }}
              fullWidth
              required
              placeholder="Naslov"
              label="Obvezno polje"
              size="small"
              defaultValue={title}
              onChange={(e) => setChangeTitle(e.target.value)}
            />
            <Button
            color="primary"
            variant="contained"
            onClick={() => {
              update_title()
            }}
          >
            Spremeni Naslov
          </Button>
            <Typography variant="h6">
              Speremni Slike:
            </Typography>
            {galleryImages.length > 0 && (
              <div>
                {galleryImages.map((image, index) => (
                  <div key={index}>
                    <img src={image.image} alt="Selected" width="200" />
                    <Checkbox
                      color="secondary"
                      onChange={(event) => handleCheck(event, image.id)}
                    />
                    <TextField
                      sx={{ marginTop: "5px", marginBottom: "20px" }}
                      defaultValue={image.description}
                      fullWidth
                      required
                      placeholder="Uredi opis slike"
                      multiline
                      size="small"
                      onChange={(e) => handleDescriptionChange(e, image.id)}
                    />
                  </div>
                ))}
                <Button
                  sx={{ marginTop: "5px" }}
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    update_image();
                  }}
                >
                  Spremeni Slike
                </Button>
                <Button
                  sx={{ marginTop: "15px" }}
                  color="error"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    delete_images();
                  }}
                >
                  Odstrani slike
                </Button>
              </div>
            )}
            <Divider orientation="horizontal" flexItem color={"black"} />
            <Typography variant="h6">
              Dodaj Slike:
            </Typography>
            <div
              {...getRootProps()}
              style={{
                cursor: "pointer",
                padding: "10px",
                border: "1px dashed #ccc",
              }}
            >
              <input {...getInputProps()} />
              <p>Drag and drop images here, or click to select images</p>
            </div>
            {addImages.length > 0 && (
              <div style={{ marginTop: "10px" }}>
                {addImages.map((file, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <span>{file.image.name}</span>
                    <Button
                      sx={{ marginLeft: "20px" }}
                      color="error"
                      variant="outlined"
                      component="span"
                      onClick={() => removeImage(index)}
                    >
                      Odstrani sliko
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button onClick={handleClose} color="error">
            Prekliči
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              onChange();
              uploadFiles();
              handleClose();
            }}
          >
            Dodaj Slike
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UpdateGalleryModal;
