import React from "react";

import Box from "@mui/material/Box";

import GalleryCard from "../components/GalleryCard";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { EffectCoverflow, Pagination } from "swiper/modules";


// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../styles.css";
import { Divider, Stack, Typography } from "@mui/material";

function GalleriesRow({ galleries, year }) {
  const slidesPerView = window.innerWidth / 290;
  const theme = createTheme({
    typography: {
      title: {
        fontWeight: 400,
        fontSize: 30,
        fontWeight: 200,
        fontStyle: "Open Sans",
        color: "#000",
      },
    },
  });
  return (
    <Box marginBottom={"40px"}>
        <ThemeProvider theme={theme}>
        <Typography marginLeft={"40px"} variant="title">
            {year}
        </Typography>
        <Divider color={"black"} flexItem variant="middle" sx={{ borderBottomWidth: 3, marginBottom: "20px" }} />
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={slidesPerView}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          navigation={true}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="mySwiper"
        >
          {galleries.map((gallery) => (
            <SwiperSlide key={gallery.id}>
              <GalleryCard
                id={gallery.id}
                title={gallery.title}
                titleImage={gallery.titleImage}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        </ThemeProvider>
    </Box>
  );
}

export default GalleriesRow;
