import React, { useContext, useState, useEffect } from "react";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";

import AuthContext from "../contex/AuthContex";

import DateFormater from "../utils/DateFunctions";

import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import backgroundImage from "../assets/default_image.jpg";

import { capitalize, renderYear } from "../utils/utils.js";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HomeIcon from "@mui/icons-material/Home";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";

function ArticleDetailPage() {
  let { authTokens, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { article_id } = useParams();

  let [article, setArticle] = useState([]);
  let [articleImages, setArticleImages] = useState([]);
  let [articleTypes, setArticleTypes] = useState([]);
  const isMobile = window.innerWidth < 800;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    get_article();
    get_articleImages();
    get_article_types();
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const delete_article = async () => {
    let response = await fetch(
      `https://api.ao-litija.si/api/articles/${article_id}`,
      {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    );
    navigate("/");
  };

  const get_article = async () => {
    let response = await fetch(
      `https://api.ao-litija.si/api/articles/${article_id}`
    );
    let data = await response.json();
    setArticle(data);
  };

  const get_articleImages = async () => {
    let response = await fetch(
      `https://api.ao-litija.si/api/article_images?article=${article_id}`
    );
    let data = await response.json();
    setArticleImages(data);
  };

  const get_article_types = async () => {
    let response = await fetch(`https://api.ao-litija.si/api/type_choices`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    });
    let data = await response.json();
    setArticleTypes(data);
  };

  const types = articleTypes.map((type) => ({
    value: type,
    label: type,
  }));
  const theme = createTheme({
    typography: {
      title: {
        fontWeight: 500,
        fontSize: 30,
        fontStyle: "Josefin Sans",
        color: "#000",
      },
      price: {
        fontWeight: 500,
        fontSize: isMobile ? 22 : 18,
        fontStyle: "Josefin Sans",
        color: "#000",
      },
      description: {
        fontWeight: 100,
        fontSize: isMobile ? 18 : 16,
        fontStyle: "Josefin Sans",
        color: "#000",
      },
      titleBox: {
        fontWeight: 500,
        fontSize: 30,
        fontStyle: "Josefin Sans",
        color: "#FFFFFF",
      },
      descriptionBox: {
        fontWeight: 500,
        fontSize: 18,
        fontStyle: "Josefin Sans",
        color: "#000",
      },
    },
  });

  return (
    <Box sx={{
      paddingTop: "20px"
    }}>
      <ThemeProvider theme={theme}>
      <Button
        sx={{
          color: "#FFF",
          width: "120px",
          borderRadius: "30px",
          backgroundColor: "#bfd630",
          marginLeft: isMobile ? "10px" : "30px",
        }}
        variant="contained"
      >
        <Link
          to={`/`}
          style={{
            textDecoration: "none",
            color: "black",
            fontWeight: "bold",
          }}
        >
          <Stack
            direction="row"
            display="flex"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <WestIcon />
            <Typography>Nazaj</Typography>
          </Stack>
        </Link>
      </Button>
        {article && (
          <div>
            <Box paddingX={isMobile ? "5%": "15%"} paddingY={"30px"}>
              <Stack>
                <Typography variant="title">{article.title}</Typography>
                <Grid container direction="row" alignItems="center">
                  <Grid
                    item
                    xs={isMobile ? 12 : 6}
                    display={"flex"}
                    justifyContent={"start"}
                  >
                    {article.user && (
                      <Typography variant="description">
                        {article.user.first_name}
                      </Typography>
                    )}
                    <Typography
                      variant="description"
                      marginX={isMobile ? "12px" : "30px"}
                    >
                      |
                    </Typography>
                    <Typography variant="description">
                      {article.type}
                    </Typography>
                    <Typography
                      variant="description"
                      marginX={isMobile ? "12px" : "30px"}
                    >
                      |
                    </Typography>
                    <Typography variant="description">
                      {DateFormater(article.created)}
                    </Typography>
                  </Grid>
                  {user && article.user && (
                    <Grid
                      item
                      xs={isMobile ? 12 : 6}
                      display={"flex"}
                      justifyContent={"end"}
                    >
                      {article.user.username === user.username ? (
                        <div>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                          >
                            <MenuItem
                              component={Link}
                              to={`/article/update/${article_id}`}
                            >
                              <EditIcon
                                sx={{ marginRight: "6px" }}
                                color="primary"
                              />
                              Spremeni objavo
                            </MenuItem>
                            <MenuItem onClick={() => delete_article()}>
                              <DeleteIcon
                                sx={{ marginRight: "6px" }}
                                color="error"
                              />
                              Izbriši
                            </MenuItem>
                          </Menu>
                        </div>
                      ) : null}
                    </Grid>
                  )}
                </Grid>
              </Stack>
            </Box>
            <Box
              sx={{
                // backgroundColor: "#919090",
                paddingX: isMobile ? "5%": "15%",
                paddingY: "50px",
              }}
            >
              <Stack gap={3}>
                <Typography
                  variant="descriptionBox"
                  dangerouslySetInnerHTML={{ __html: article.body }}
                ></Typography>
              </Stack>
            </Box>
          </div>
        )}
        {article.gallery && (
          <Box sx={{ justifyContent: "center", paddingX: isMobile ? "5%": "15%", paddingBottom: "30px",}}>
          <Button sx={{ color: "#FFF", width: "200px", borderRadius: "30px", backgroundColor: "#bfd630", textTransform: 'none', }} variant="contained">
            <Link
              to={`/galerije/${article.gallery.id}`}
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bold",
              }}
            >
              <Stack
                direction="row"
                display="flex"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Typography>Poglej galerijo</Typography>
                <EastIcon />
              </Stack>
            </Link>
          </Button>
        </Box>
        )}
        {article && (
          <Carousel
            showThumbs={articleImages.length > 0}
            showIndicators={articleImages.length > 0}
            swipeable={true}
            showArrows={true}
            onChange={() => {}}
            onClickItem={() => {}}
            onClickThumb={() => {}}
          >
            <Box
              sx={{
                height: isMobile ? "60vh" : "80vh",
                aspectRatio: "auto",
                maxWidth: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={article.titleImage ? article.titleImage : backgroundImage}
                alt="Real Estate"
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
            {articleImages.map((image) => (
              <Box
                key={image.id}
                sx={{
                  height: isMobile ? "60vh" : "80vh",
                  aspectRatio: "auto",
                  maxWidth: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={image.image || image.mainImage}
                  alt="Real Estate"
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    objectFit: "contain",
                  }}
                />
                <p className="legend" style={{backgroundColor: "rgba(0,0,0,0)", fontSize: "20px"}}>{image.description}</p>
              </Box>
            ))}
          </Carousel>
        )}
      </ThemeProvider>
    </Box>
  );
}

export default ArticleDetailPage;
