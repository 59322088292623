import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import CardActionArea from "@mui/material/CardActionArea";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HomeIcon from "@mui/icons-material/Home";

import {capitalize, renderYear} from "../utils/utils.js";

import { Link } from "react-router-dom";

function GalleryCard({ id, titleImage, title }) {
    const theme = createTheme({
        typography: {
          title: {
            fontWeight: 400,
            fontSize: 25,
            fontStyle: "Josefin Sans",
            color: '#c7c5c5',
          },
          description: {
            fontWeight: 200,
            fontSize: 16,
            fontStyle: "Josefin Sans",
            color: "white",
          },
        },
      });
  return (
    <Card sx={{ width: 290}}>
      <CardActionArea component={Link} to={`/galerije/${id}`}>
        <CardMedia sx={{ height: 300 }} image={titleImage} title="green iguana" />
        <CardContent sx={{backgroundColor: "#1f4154"}}>
          <ThemeProvider theme={theme}>
            <Typography variant="title" component="div">
              {title}
            </Typography>
          </ThemeProvider>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default GalleryCard