import React, { useContext, useState } from "react";

import AuthContext from "../contex/AuthContex";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function LoginModal({ onLogin }) {
  let { loginUser } = useContext(AuthContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (onLogin !== undefined && onLogin !== null) {
      onLogin();
    }
    setOpen(true);
  };

  const handleClose = () => {
    if (onLogin !== undefined && onLogin !== null) {
      onLogin();
    }
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="inherit"
        onClick={handleClickOpen}
        sx={{ borderRadius: "50px" }}
      >
        Prijava
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ backgroundColor: "#ededed" }}>
          Prijavi se
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#ededed" }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Uporabniški ime"
            type="text"
            fullWidth
            variant="standard"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Geslo"
            type="password"
            fullWidth
            variant="standard"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#ededed", padding: "10px" }}>
          <Button color="inherit" onClick={handleClose}>
            Prekliči
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => {
              loginUser(username, password);
              handleClose();
            }}
          >
            Prijavi se
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LoginModal;
