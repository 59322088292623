import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../contex/AuthContex";

import ForumPost from "../components/ForumPost";
import CreateForumPostModal from "../components/CreateForumPostModal";

import DateFormater from "../utils/DateFunctions";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

function ForumProfileView({user_id}) {
  let [forumPosts, setForumPosts] = useState([]);

  let { authTokens } = useContext(AuthContext);

  useEffect(() => {
    get_forumPosts();
    window. scrollTo(0, 0) 
  }, []);

  const get_forumPosts = async () => {
    let response = await fetch(`https://api.ao-litija.si/api/forum?user_id=${user_id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    let data = await response.json();
    data.sort((a, b) => new Date(b.created) - new Date(a.created));
    setForumPosts(data);
  };

  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{margin: "20px", minHeight: "80vh"}}>
      
      <Stack gap={3}>
      <CreateForumPostModal onCreate={get_forumPosts} />
        {forumPosts.map((post) => (
          <ForumPost
            key={post.id}
            forum_post_id={post.id}
            title={post.title}
            body={post.body}
            created={DateFormater(post.created)}
            author={post.user.first_name}
            comments={post.num_of_comments}
            username={post.user.username}
            onChange={get_forumPosts}
            userId={post.user.id}
          />
        ))}
      </Stack>
    </Box>
  );
}

export default ForumProfileView;
