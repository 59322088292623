import React from "react";

import backgroundImage from "../assets/alpinisticna_sola.png";

import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import ListItem from "@mui/material/ListItem";

function SchoolPage() {
  const isMobile = window.innerWidth < 800;
  const theme = createTheme({
    typography: {
      title: {
        fontWeight: 1000,
        fontSize: isMobile ? 20 : 32,
        fontStyle: "Josefin Sans",
      },
      text: {
        fontSize: isMobile ? 14 : 16,
        fontStyle: "Josefin Sans",
      },
    },
  });

  let letna_tehnika = [
    "Uvodno predstavitveno predavanje",
    "Oprema",
    "Vozli",
    "Športno plezanje in trening",
    "Priprava na alpinistično turo, izrazoslovje, skice",
    "Medicina, prva pomoč",
    "Orientacija, poznavanje gorstev",
    "Nevarnosti v gorah in GRS - Gorska reševalna služba",
  ];

  let prakticne_vaje = [
    "Vozli",
    "Sidrišča",
    "Gibanje naveze",
    "Sidrišča za spust po vrvi",
    "Spust po vrvi",
    "Samoreševanje",
    "Reševanje padlega soplezalca",
    "Ponavljanje + napredne tehnike",
  ];

  let zimska_tehnika = [
    "Pravilna hoja z derezami in cepinom",
    "Pravilno zaustavljanje s cepinom v primeru zdrsa",
    "Sidrišča v snegu",
    "Gibanje naveze v snegu",
    "Sidrišča za spust po vrvi v snegu",
    "Spust po vrvi v snegu",
    "Varnost pred plazovi",
    "Pravilna uporaba lavinskega trojčka (lavinske žolne, sonde in lopate) in iskanje ter reševanje zasutega",
    "Ledno plezanje",
  ];

  return (
    <Box
      sx={{
        paddingY: "30px",
        paddingX: isMobile ? "10%" : "15%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{ maxWidth: "1200px", paddingTop: "30px" }}>
        <ThemeProvider theme={theme}>
          <Stack>
            <Typography variant="title" sx={{ paddingY: "20px" }}>
              ALPINISTIČNA ŠOLA 2024/25
            </Typography>
            <Typography variant="text">
              Alpinistično izobraževanje je namenjeno vsem, ki bi se radi
              naučili osnov varnega plezanja v gorah ter vsem, ki bi radi svoje
              znanje izpopolnili. Uvodno predavanje za alpinistično šolo 2024/25
              bo 17.10.2024 v prostorih AO Litija, Ljubljanska cesta 10, Litija.
            </Typography>
            <Box
              sx={{
                display: "flex",
                aspectRatio: "auto",
                justifyContent: "center",
                alignItems: "center",
                paddingY: "20px",
              }}
            >
              <img
                src={backgroundImage}
                alt="Real Estate"
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "contain",
                  boxShadow: "1px 2px 9px #1f4154",
                }}
              />
            </Box>
            <Typography variant="text" sx={{ paddingY: "20px" }}>
              Program šole obsega teoretična predavanja in praktične vaje letne
              in zimske tehnike.
            </Typography>
            <Typography variant="text" sx={{ paddingY: "20px" }}>
              LETNA TEHNIKA:
            </Typography>
            {letna_tehnika.map((e) => (
              <Typography key={e}>
                <ListItem
                  sx={{
                    display: "list-item",
                    paddingY: isMobile ? "2px" : "5px",
                  }}
                >
                  <Typography variant="text">{e}</Typography>
                </ListItem>
              </Typography>
            ))}
            <Typography variant="text" sx={{ paddingY: "20px" }}>
              PRAKTIČNE VAJE:
            </Typography>
            {prakticne_vaje.map((e) => (
              <Typography key={e}>
                <ListItem
                  sx={{
                    display: "list-item",
                    paddingY: isMobile ? "2px" : "5px",
                  }}
                >
                  <Typography variant="text">{e}</Typography>
                </ListItem>
              </Typography>
            ))}
            <Typography variant="text" sx={{ paddingY: "20px" }}>
              ZIMSKA TEHNIKA:
            </Typography>
            {zimska_tehnika.map((e) => (
              <Typography variant="text" key={e}>
                <ListItem
                  sx={{
                    display: "list-item",
                    paddingY: isMobile ? "2px" : "5px",
                  }}
                >
                  <Typography variant="text">{e}</Typography>
                </ListItem>
              </Typography>
            ))}
          </Stack>
        </ThemeProvider>
      </Box>
    </Box>
  );
}

export default SchoolPage;
