import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../contex/AuthContex";
import { Link } from "react-router-dom";

import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import GalleriesRow from "../components/GalleriesRow";
import CreateGalleryModal from "../components/CreateGalleryModal";

function GaleriesProfileView({user_id}) {
  let [galleries, setGalleries] = useState([]);
  let [createGallery, setCreateGallery] = useState();
  let { user, logoutUser } = useContext(AuthContext);

  let { authTokens } = useContext(AuthContext);

  useEffect(() => {
    get_galleries();
    window. scrollTo(0, 0) 
  }, []);

  const add_galleries_to_year = (data, year) => {
    var finalData = [];
    for (var gallery of data) {
      var date = new Date(gallery.created);
      if (year === date.getFullYear()) {
        finalData.push(gallery);
      }
    }

    return finalData;
  };

  const filter_galleries = (data) => {
    var finalData = [];
    var years = new Set();
    for (var gallery of data) {
      var date = new Date(gallery.created);
      years.add(date.getFullYear());
    }
    for (const year of years) {
      var galeries = add_galleries_to_year(data, year);
      finalData.push({ year: year, galeries: galeries });
    }

    finalData.sort((a, b) => b.year - a.year);

    return finalData;
  };

  const get_galleries = async () => {
    let response = await fetch(`https://api.ao-litija.si/api/galleries?user_id=${user_id}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    });
    let data = await response.json();

    setGalleries(filter_galleries(data));
  };

  const delete_gallery = async (id) => {
    await fetch(`https://api.ao-litija.si/api/galleries/${id}`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    get_galleries();
  };
  const theme = createTheme({
    typography: {
      title: {
        fontWeight: 400,
        fontSize: 50,
        fontWeight: 200,
        fontStyle: "Open Sans",
        color: "#000",
      },
    },
  });

  return (
    <Box marginTop={"40px"} sx={{minHeight: "87vh"}}>
      <ThemeProvider theme={theme}>
        <Grid container alignItems={"center"}>
          <Grid
            item
            xs={12}
            alignItems={"center"}
            justifyContent={"end"}
            display={"flex"}
          >
            {user && (
              <CreateGalleryModal onAdd={get_galleries}/>
            )}
          </Grid>
        </Grid>
        {galleries &&
          galleries.map((galleries_by_year) => (
            <GalleriesRow
              key={galleries_by_year.year}
              galleries={galleries_by_year.galeries}
              year={galleries_by_year.year}
            />
          ))}

      </ThemeProvider>
    </Box>
  );
}

export default GaleriesProfileView;
