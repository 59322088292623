import React, { useContext, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import AuthContext from "../contex/AuthContex";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container } from "@mui/material";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import ForumIcon from "@mui/icons-material/Forum";
import EditNoteTwoToneIcon from "@mui/icons-material/EditNoteTwoTone";
import PersonIcon from "@mui/icons-material/Person";

import logo from "../assets/logo.png";
import { renderTitle } from "../utils/utils.js";
import InstagramIcon from "@mui/icons-material/Instagram";

import LoginModal from "./LoginModal";
import Profile from "./Profile";

const pages = [
  "Domov",
  "Novice",
  "Vzponi",
  "Galerije",
  "Odsek",
  "Alpinistična_Šola",
];
const drawerWidth = 240;

function MainNavbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();
  let { user, logoutUser } = useContext(AuthContext);
  const [isPermanent, setIsPermanent] = useState(false);

  const handleDrawerToggle = () => {
    if (!isPermanent) {
      setMobileOpen((prevState) => !prevState);
    }
  };

  const handleLoginClick = () => {
    setIsPermanent((prevState) => !prevState);
  };

  let mobileProfileMenu = [
    { name: "Profil", path: "profil", icon: <PersonIcon fontSize="small" /> },
    { name: "Forum", path: "forum", icon: <ForumIcon fontSize="small" /> },
    {
      name: "Nova Objava",
      path: "create_article",
      icon: <EditNoteTwoToneIcon fontSize="small" />,
    },
    {
      name: "Nastavitve",
      path: `settings/${user && user.user_id}`,
      icon: <Settings fontSize="small" />,
    },
  ];

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2, color: "white" }}>
        Menu
      </Typography>
      <Divider />
      <List>
        {pages.map((page) => (
          <ListItem key={page} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <Link
                to={`/${page === "Domov" ? "" : page}`}
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontStyle: "Josefin Sans",
                  fontWeight: "bold",
                }}
              >
                {renderTitle(page)}
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
        <Divider flexItem color={"black"} />
        {user ? (
          <div>
            <Box
              display={"flex"}
              alignItems={"center"}
              sx={{ marginY: "20px" }}
            >
              <Avatar sx={{ width: 32, height: 32, marginX: "20px" }}>
                {user.firstName !== ""
                  ? user.firstName.charAt(0).toUpperCase()
                  : user.username.charAt(0).toUpperCase()}
              </Avatar>
              <Typography sx={{ color: "white" }}>
                {user.firstName !== "" ? user.firstName : user.username}
              </Typography>
            </Box>
            {mobileProfileMenu.map((menu) => (
              <ListItem key={menu.name}>
                <ListItemIcon sx={{ color: "white" }}>{menu.icon}</ListItemIcon>
                <Link
                  to={`/${menu.path}`}
                  style={{
                    textDecoration: "none",
                    color: "white",
                  }}
                >
                  {menu.name}
                </Link>
              </ListItem>
            ))}
            <ListItemButton
              sx={{ color: "white" }}
              onClick={() => {
                logoutUser();
                navigate("/");
              }}
            >
              <ListItemIcon sx={{ color: "white" }}>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </ListItemButton>
          </div>
        ) : (
          <ListItemButton
            sx={{ textAlign: "center" }}
          >
            <LoginModal onLogin={handleLoginClick}/>
          </ListItemButton>
        )}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" sx={{ background: "#1f4154" }}>
        <Toolbar
          sx={{ background: "#1f4154", justifyContent: "space-between" }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: "none" } }}
          >
            <MenuIcon color="inherit" />
          </IconButton>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              onClick={() => {
                navigate("/");
                navigate(0);
              }}
              component="img"
              sx={{
                height: 64,
              }}
              alt="Your logo."
              src={logo}
            />
            <IconButton href="https://www.instagram.com/ao_litija/">
              <InstagramIcon sx={{ marginLeft: "20px", color: "#bfd630" }} />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{ display: { xs: "none", lg: "block" } }}>
            <Stack
              direction="row"
              alignItems="center"
              gap={3}
              justifyContent={"center"}
            >
              {pages.map((page) => (
                <Button key={page} sx={{ color: "#FFF" }}>
                  <Link
                    to={`/${page === "Domov" ? "" : page}`}
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontWeight: "bold",
                      marginRight: "30px",
                    }}
                  >
                    {renderTitle(page)}
                  </Link>
                </Button>
              ))}

              {user ? <Profile /> : <LoginModal />}
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#1f4154",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Container component="main" maxWidth={false} className="p-0">
        <Toolbar />
        <Outlet />
      </Container>
    </Box>
  );
}

export default MainNavbar;
