
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";

import Article from "../components/Article";
import Footer from "../components/Footer";

import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

import { Image } from "mui-image";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

import DateFormater from "../utils/DateFunctions";

function ArticleProfileView({user_id}) {
  let [articles, setArticles] = useState([]);
  
  useEffect(() => {
    get_articles();
    window. scrollTo(0, 0) 
  }, []);

  // Put this fun into util
  const get_articles = async () => {
    let response = await fetch(`https://api.ao-litija.si/api/articles?user_id=${user_id}`);
    let data = await response.json();
    setArticles(data.results);
  };
  const isMobile = window.innerWidth < 800;

  const theme = createTheme({
    typography: {
      title: {
        fontWeight: 400,
        fontSize: 35,
        fontStyle: "Open Sans",
        color: "#c7c5c5",
      },
      boxTitle: {
        fontWeight: 400,
        fontSize: isMobile ? 20 : 32,
        fontStyle: "Open Sans",
        color: "#FFFFFF",
      },
      boxtext: {
        fontWeight: 200,
        fontSize: isMobile ? 14 : 20,
        fontStyle: "Open Sans",
        color: "#FFFFFF",
      },
    },
  });

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <Stack>
          <Stack
            gap={3}
            alignItems={"center"}
            display={"flex"}
            marginY={"20px"}
            divider={<Divider color={"black"} flexItem variant="middle" sx={{ borderBottomWidth: 2 }} />}
          >
            {articles.map((article, index) => (
              <Article
                key={article.id}
                article_id={article.id}
                title={article.title}
                titleImage={article.titleImage}
                body={article.body}
                created={DateFormater(article.created)}
                author={article.user.first_name === "" ? article.user.username : article.user.first_name }
                gallery={article.gallery}
                type={article.type}
                index={index}
              />
            ))}
          </Stack>
        </Stack>
      </ThemeProvider>
    </Box>
  );
}

export default ArticleProfileView;
