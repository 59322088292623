import React, { useContext, useState } from "react";
import AuthContext from "../contex/AuthContex";

import DateFormater from "../utils/DateFunctions";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import profileImage from "../assets/no_profile.jpg";
import TextField from "@mui/material/TextField";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


function Comment({ comment, onDelete }) {
  let [isEditing, setIsEditing] = useState(false);
  let [commentUpdate, setCommentUpdate] = useState(comment.body);
  let { authTokens, user } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const update_forumPost = async (id) => {
    await fetch(`https://api.ao-litija.si/api/comments/${id}`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify(commentUpdate),
    });
    onDelete();
    setIsEditing(false);
  };

  const delete_comment = async (comment_id) => {
    await fetch(`https://api.ao-litija.si/api/comments/${comment_id}`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    onDelete();
  };

  return (
    <Card
      sx={{
        maxWidth: 650,
        backgroundColor:
          comment.user.username === user.username ? "#abbade" : "#c4c5cc",
      }}
    >
      <CardHeader
        avatar={<Avatar alt="Remy Sharp" src={profileImage} />}
        action={
          comment.user.username === user.username ? (
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => setIsEditing(true)}
                >
                  <EditIcon sx={{marginRight: "6px"}} color="primary" />
                  Spremeni
                </MenuItem>
                <MenuItem
                  onClick={() => delete_comment(comment.id)}
                >
                  <DeleteIcon sx={{marginRight: "6px"}} color="error" />
                  Izbriši
                </MenuItem>
              </Menu>
            </div>
          ) : null
        }
        title={comment.user.first_name}
        subheader={DateFormater(comment.created)}
      />
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          paddingX: "30px",
        }}
      >
        {isEditing ? (
          <TextField
            fullWidth
            id="standard-multiline-static"
            multiline
            defaultValue={comment.body}
            onChange={(e) => setCommentUpdate({ body: e.target.value })}
          />
        ) : (
          <Typography variant="body2" color="text.secondary">
            {comment.body}
          </Typography>
        )}
      </CardContent>
      {isEditing ? (
        <Stack
          direction="row"
          display={"flex"}
          justifyContent={"center"}
          gap={2}
        >
          <IconButton onClick={() => update_forumPost(comment.id)}>
            <CheckIcon color="success" />
          </IconButton>
          <IconButton onClick={() => setIsEditing(false)}>
            <ClearIcon color="error" />
          </IconButton>
        </Stack>
      ) : null}
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites"></IconButton>
      </CardActions>
    </Card>
  );
}

export default Comment;
