import React, { useContext, useState, useEffect } from "react";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";

import AuthContext from "../contex/AuthContex";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Box, Grid, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";

import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useDropzone } from "react-dropzone";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function UpdateArticlePage() {
  let { authTokens, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { article_id } = useParams();

  let [article, setArticle] = useState([]);
  let [body, setBody] = useState("");
  let [images, setImages] = useState([]);
  let [galleries, setGalleries] = useState([]);
  let [articleTypes, setArticleTypes] = useState([]);
  let [chosenArticleType, setChosenArticleType] = useState("");
  let [chosenGallery, setChosenGallery] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  let [addImages, setAddImages] = useState([]);
  const [deleteImages, setDeleteImages] = useState([]);
  const [imagesDescriptions, setImagesDescriptions] = useState([]);

  const [imageChanged, setImageChanged] = useState(false);

  useEffect(() => {
    get_article();
    get_articleImages();
    get_article_types();
    get_galleries();
    window.scrollTo(0, 0);
  }, []);

  const update_article = async () => {
    var formData = new FormData();

    formData.append("title", article.title);

    formData.append("body", body);

    formData.append("type", chosenArticleType);
    if (imageChanged && article.titleImage != null) {
      formData.append("titleImage", article.titleImage);
    }
    if (chosenGallery) {
      formData.append("gallery", chosenGallery);
    }
    if (formData.entries().next().value) {
      await fetch(`https://api.ao-litija.si/api/articles/${article_id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: formData,
      });
    }
    navigate(`/article/${article_id}`);
  };

  const get_article = async () => {
    let response = await fetch(
      `https://api.ao-litija.si/api/articles/${article_id}`
    );
    let data = await response.json();
    setArticle(data);
    setBody(data.body);
    if (data.gallery) {
      setChosenGallery(data.gallery.id);
    }
    setChosenArticleType(data.type);
    setSelectedImage(data.titleImage);
  };

  const get_articleImages = async () => {
    let response = await fetch(
      `https://api.ao-litija.si/api/article_images?article=${article_id}`
    );
    let data = await response.json();
    setImages(data);
  };

  const get_article_types = async () => {
    let response = await fetch(`https://api.ao-litija.si/api/type_choices`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    });
    let data = await response.json();
    setArticleTypes(data);
  };

  const get_galleries = async () => {
    let response = await fetch(`https://api.ao-litija.si/api/galleries`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    let data = await response.json();
    setGalleries(data);
  };

  const handleCheck = (e, id) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setDeleteImages([...deleteImages, id]);
    } else {
      setDeleteImages(deleteImages.filter((checkedId) => checkedId !== id));
    }
  };

  const delete_images = async () => {
    await fetch(`https://api.ao-litija.si/api/article_images/delete`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({ ids: deleteImages }),
    });
    get_articleImages();
  };

  const uploadFiles = async () => {
    var formData = new FormData();
    addImages.forEach((file) => formData.append("images", file));
    formData.append("article_id", article_id);
    await fetch(`https://api.ao-litija.si/api/article_images/create`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: formData,
    });
    get_articleImages();
  };

  const update_image = async () => {
    await fetch(`https://api.ao-litija.si/api/article_images/delete`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({ descriptions: imagesDescriptions }),
    });
  };

  const removeImage = (index) => {
    setAddImages(addImages.filter((image, i) => i !== index));
  };

  const onDrop = (acceptedImages) => {
    setAddImages([...addImages, ...acceptedImages]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setArticle({ ...article, titleImage: event.target.files[0] });
    setImageChanged(true);
    if (file) {
      // Set the selected image in state
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const handleDescriptionChange = (e, id) => {
    var descriptionsWithoutCurrent = imagesDescriptions.filter(
      (current) => current.id !== id
    );

    setImagesDescriptions([
      ...descriptionsWithoutCurrent,
      { id: id, description: e.target.value },
    ]);
  };

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Card
        sx={{ maxWidth: "800px", margin: "16px", backgroundColor: "#e6e3e3" }}
      >
        <CardContent>
          <Box sx={{ width: "1000px" }}></Box>
          <Stack gap={2}>
            <Grid container alignItems={"center"} gap={1}>
              <Grid
                item
                xs={12}
                alignItems={"center"}
                justifyContent={"center"}
                display={"flex"}
              >
                Spremeni Objavo
              </Grid>
            </Grid>
            <Grid container alignItems={"center"} gap={1}>
              <Grid item xs={6}>
                <Typography>Naslov:</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  defaultValue={article.title}
                  multiline
                  required
                  placeholder="Naslov"
                  size="small"
                  onChange={(e) =>
                    setArticle({ ...article, title: e.target.value })
                  }
                />
              </Grid>
            </Grid>
            <Grid container alignItems={"center"} gap={1}>
              <Grid item xs={6}>
                <Typography>Vsebina:</Typography>
              </Grid>
              <Grid item xs={12}>
                <div sx={{ minHeight: "40px" }}>
                  <ReactQuill
                    placeholder="Obvezno polje*"
                    theme="snow"
                    value={body}
                    onChange={setBody}
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        [{ list: "ordered" }, { list: "bullet" }],
                      ],
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            {articleTypes && (
              <Grid container alignItems={"center"} gap={1}>
                <Grid item xs={6}>
                  <Typography>Tip Objave:</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Obvezno polje*
                    </InputLabel>
                    <Select
                      value={chosenArticleType}
                      label="Obvezno polje*"
                      onChange={(e) => setChosenArticleType(e.target.value)}
                    >
                      {articleTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <Divider
              color={"black"}
              flexItem
              variant="middle"
              sx={{ borderBottomWidth: 2 }}
            />
            <Grid container alignItems={"center"} gap={1}>
              <Grid item xs={6}>
                <Typography>Pripni Galerijo:</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Opcijsko polje
                  </InputLabel>
                  <Select
                    label="Opcijsko polje"
                    value={chosenGallery}
                    onChange={(e) => setChosenGallery(e.target.value)}
                  >
                    {galleries.map((gallery) => (
                      <MenuItem key={gallery.id} value={gallery.id}>
                        {gallery.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container alignItems={"center"} gap={1}>
              <Grid item xs={6}>
                <Typography>Spremeni Naslovno Sliko:</Typography>
              </Grid>
              <Grid item xs={12}>
                {selectedImage === null ? (
                  <div>
                    <Input
                      accept="image/*"
                      id="image-input"
                      type="file"
                      style={{ display: "none" }}
                      onChange={(event) => (
                        handleImageChange(event),
                        setArticle({
                          ...article,
                          titleImage: event.target.files[0],
                        })
                      )}
                    />
                    <label htmlFor="image-input">
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                      >
                        Naloži Naslovno sliko
                      </Button>
                    </label>
                  </div>
                ) : (
                  <div>
                    <img src={selectedImage} alt="Selected" width="200" />
                    <Button
                      sx={{ marginLeft: "20px",marginTop: "5px" }}
                      color="error"
                      variant="outlined"
                      onClick={() => (
                        setSelectedImage(null),
                        setArticle({
                          ...article,
                          titleImage: null,
                        })
                      )}
                    >
                      Odstrani sliko
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>
            <Button
              variant="contained"
              component="span"
              onClick={() => update_article()}
            >
              Spremeni objavo
            </Button>
            <Divider orientation="horizontal" flexItem color={"black"} />
            <Grid container alignItems={"center"} gap={1}>
              <Grid item xs={6}>
                <Typography>Odstrani slike:</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {images.length > 0 &&
                    images.map((image, index) => (
                      <Grid item xs={12} sm={4} md={4} key={index}>
                        <Stack direction={"row"}>
                          <img src={image.image} alt="Selected" width="200" />
                          <Checkbox
                            color="secondary"
                            onChange={(event) => handleCheck(event, image.id)}
                          />
                        </Stack>

                        <TextField
                          sx={{ marginTop: "5px", marginBottom: "20px" }}
                          defaultValue={image.description}
                          fullWidth
                          required
                          placeholder="Uredi opis slike"
                          size="small"
                          onChange={(e) => handleDescriptionChange(e, image.id)}
                        />
                      </Grid>
                    ))}
                </Grid>
                <Stack direction={"row"}>
                <Button
                  sx={{ marginTop: "20px" }}
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    update_image();
                  }}
                >
                  Spremeni
                </Button>
                <Box sx={{width: "20px"}}></Box>
                <Button
                  sx={{ marginTop: "20px" }}
                  fullWidth
                  color="error"
                  variant="contained"
                  onClick={() => {
                    delete_images();
                  }}
                >
                  Odstrani
                </Button>
                </Stack>
              </Grid>
            </Grid>
            <Grid container alignItems={"center"} gap={1}>
              <Grid item xs={6}>
                <Typography>Dodaj Slike:</Typography>
              </Grid>
              <Grid item xs={12}>
                <div
                  {...getRootProps()}
                  style={{
                    cursor: "pointer",
                    padding: "10px",
                    border: "1px dashed #ccc",
                  }}
                >
                  <input {...getInputProps()} />
                  <p>Drag and drop images here, or click to select images</p>
                </div>
                {addImages.length > 0 && (
                  <div style={{ marginTop: "10px" }}>
                    {addImages.map((file, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <span>{file.name}</span>
                        <Button
                          sx={{ marginLeft: "20px" }}
                          color="error"
                          variant="outlined"
                          component="span"
                          onClick={() => removeImage(index)}
                        >
                          Odstrani sliko
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
                <Button
                  sx={{ marginTop: "20px" }}
                  color="primary"
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    uploadFiles();
                  }}
                >
                  Dodaj Slike
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}

export default UpdateArticlePage;
