import React, { useContext, useState } from "react";

import AuthContext from "../contex/AuthContex";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import { useDropzone } from "react-dropzone";

function CreateForumPostModal({ onCreate }) {
  let [post, setPost] = useState([]);
  let [images, setImages] = useState([]);

  const [open, setOpen] = useState(false);

  let { authTokens, user } = useContext(AuthContext);

  const create_forumPost = async () => {
    var formData = new FormData();

    images.forEach((image) => formData.append("images", image));
    formData.append("body", post.body);
    formData.append("title", post.title);

    await fetch(`https://api.ao-litija.si/api/forum`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: formData,
    });
    onCreate();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setImages([]);
    setPost("");
    setOpen(false);
  };

  const onDrop = (acceptedImages) => {
    setImages([...images, ...acceptedImages]);
  };

  const removeImage = (index) => {
    setImages(images.filter((image, i) => i !== index));
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div>
      <Button
        color="inherit"
        variant="contained"
        onClick={handleClickOpen}
        fullWidth={true}
        sx={{
          borderRadius: "100px",
          backgroundColor: "#e6e3e3",
          color: "#000",
        }}
      >
        Napiši objavo...
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ marginTop: "5px" }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>O čem razmišljaš?</DialogTitle>
        <DialogContent>
          <Stack direction={"column"} gap={2}>
          <TextField
              sx={{ marginTop: "5px" }}
              fullWidth
              required
              placeholder="Naslov"
              label="Obvezno polje"
              size="small"
              onChange={(e) => setPost({...post, title: e.target.value })}
            />
            <TextField
              sx={{ marginTop: "5px" }}
              fullWidth
              required
              placeholder="Vsebina"
              label="Obvezno polje"
              multiline
              size="small"
              minRows={5}
              onChange={(e) => setPost({...post, body: e.target.value })}
            />
            <div
              {...getRootProps()}
              style={{
                cursor: "pointer",
                padding: "10px",
                border: "1px dashed #ccc",
              }}
            >
              <input {...getInputProps()} />
              <p>Povleci slike ali klikni, da izbereš slike</p>
            </div>
            {images.length > 0 && (
              <div style={{ marginTop: "10px" }}>
                {images.map((file, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <span>{file.name}</span>
                    <Button
                      sx={{marginLeft: "20px"}}
                      color="error"
                      variant="outlined"
                      onClick={() => removeImage(index)}
                      style={{ marginLeft: "10px" }}
                    >
                      Odstrani sliko
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button onClick={handleClose} color="error">
            Prekliči
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              create_forumPost();
              handleClose();
            }}
          >
            Objavi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateForumPostModal;
