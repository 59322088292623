import React from "react";
import { useParams } from "react-router-dom";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArticleProfileView from "../components/ArticleProfileView";
import GaleriesProfileView from "../components/GaleriesProfileView";
import ForumProfileView from "../components/ForumProfileView";




function CustomTabPanel(props) {
  const { children, value, index, user_id, ...other } = props;
  var views = [<ArticleProfileView user_id={user_id}/>, <GaleriesProfileView user_id={user_id}/>, <ForumProfileView user_id={user_id}/>]

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
          views[index]
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ProfilePage() {
  const [value, setValue] = React.useState(0);
  const { userId } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', minHeight: "85vh" }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Objave" {...a11yProps(0)} />
          <Tab label="Galerije" {...a11yProps(1)} />
          <Tab label="Blogi" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} user_id={userId}>
        Objave
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} user_id={userId}>
        Galerije
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2} user_id={userId}>
        Blogi
      </CustomTabPanel>
    </Box>
  );
}


export default ProfilePage;
