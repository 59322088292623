import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";

import Article from "../components/Article";
import Footer from "../components/Footer";

import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

import backgroundImage from "../assets/naslovna_slika.jpg";
import { Image } from "mui-image";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

import DateFormater from "../utils/DateFunctions";
import Pagination from "@mui/material/Pagination";

function HomePage() {
  let [articles, setArticles] = useState([]);
  let [count, setCount] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    get_articles();
  }, []);

  // Put this fun into util
  const get_articles = async (url) => {
    let response = await fetch(
      url !== null && url !== undefined
        ? url
        : `https://api.ao-litija.si/api/articles`
    );
    let data = await response.json();
    setArticles(data.results);
    setCount(data.count);
    window.scrollTo(0, 0);
  };
  const isMobile = window.innerWidth < 800;

  const handleChangePagination = (event, value) => {
    setPage(value);
    get_articles(`https://api.ao-litija.si/api/articles?page=${value}`);
    window.scrollTo(0, 0);
  };

  const theme = createTheme({
    typography: {
      title: {
        fontWeight: 400,
        fontSize: 35,
        fontStyle: "Open Sans",
        color: "#FFFFFF",
      },
      boxTitle: {
        fontWeight: 400,
        fontSize: isMobile ? 20 : 32,
        fontStyle: "Open Sans",
        color: "#FFFFFF",
      },
      boxtext: {
        fontWeight: 200,
        fontSize: isMobile ? 14 : 20,
        fontStyle: "Open Sans",
        color: "#FFFFFF",
      },
    },
  });

  return (
    <Box>
      <Box>
        <Image src={backgroundImage} />
      </Box>
      <ThemeProvider theme={theme}>
        <Stack>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"80px"}
            sx={{
              backgroundColor: "#1f4154",
            }}
          >
            <Typography variant="title">Najnovejše objave</Typography>
          </Box>
          <Stack
            gap={3}
            alignItems={"center"}
            display={"flex"}
            marginY={"20px"}
            divider={
              <Divider
                color={"black"}
                flexItem
                variant="middle"
                sx={{ borderBottomWidth: 2 }}
              />
            }
          >
            {articles.map((article, index) => (
              <Article
                key={article.id}
                article_id={article.id}
                title={article.title}
                titleImage={article.titleImage}
                body={article.body}
                created={DateFormater(article.created)}
                author={
                  article.user.first_name === ""
                    ? article.user.username
                    : article.user.first_name
                }
                gallery={article.gallery}
                type={article.type}
                index={index}
              />
            ))}
          </Stack>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              marginY: "30px",
            }}
          >
            <Pagination
              count={Math.ceil(count / 10)}
              page={page}
              onChange={handleChangePagination}
            />
          </Box>
        </Stack>
      </ThemeProvider>
    </Box>
  );
}

export default HomePage;
