import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";

const AuthContext = createContext();

export default AuthContext;

export const AuthPrivider = ({ children }) => {
  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  let [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  let [loading, setLoading] = useState(true);

  const loginUser = async (username, password) => {
    let respone = await fetch(`https://api.ao-litija.si/api/token`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ username: username, password: password }),
    });
    let data = await respone.json();
    if (respone.status === 200) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
    } else {
      alert("something went wrong");
    }
  };

  const logoutUser = async () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
  };
  // Here you have a bug ==> if the user is loged in, if you refresh the page sometimes you get a 401 Unathorized
  let updateToken = async () => {
    let respone = await fetch(`https://api.ao-litija.si/api/token/refresh`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ refresh: authTokens?.refresh }),
    });
    let data = await respone.json();
    if (respone.status === 200) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
    } else {
      logoutUser();
    }

    if (loading) {
      setLoading(false);
    }
  };

  let contextData = {
    user: user,
    authTokens: authTokens,

    loginUser: loginUser,
    logoutUser: logoutUser,

    updateToken: updateToken,
  };

  useEffect(() => {
    if (loading) {
      if (authTokens) {
        updateToken();
      } else {
        setLoading(false);
      }
    }

    let refreshInterval = 1000 * 60 * 7;
    let interval = setInterval(() => {
      if (authTokens) {
        updateToken();
      }
    }, refreshInterval);
    return () => clearInterval(interval);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
