import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

import AuthContext from "../contex/AuthContex";

import { useDropzone } from "react-dropzone";
import ImageGallery from "react-image-gallery";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Stack from "@mui/material/Stack";
import WestIcon from "@mui/icons-material/West";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import UpdateGalleryModal from "../components/UpdateGalleryModal";

function GalleryPage() {
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);

  let { authTokens, user } = useContext(AuthContext);
  const { galleryId } = useParams();
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 800;

  useEffect(() => {
    get_images();
    window.scrollTo(0, 0);
  }, []);

  const get_images = async () => {
    let response = await fetch(
      `https://api.ao-litija.si/api/gallery_images?gallery=${galleryId}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    let data = await response.json();
    setImages(data);
    setTitle(data[0].gallery.title);
  };

  const delete_gallery = async (id) => {
    await fetch(`https://api.ao-litija.si/api/galleries/${galleryId}`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    navigate("/galerije");
  };

  return (
    <Box marginY={"20px"} sx={{ minHeight: "87vh" }}>
      <Button
        sx={{
          color: "#FFF",
          width: "120px",
          borderRadius: "30px",
          backgroundColor: "#bfd630",
          marginLeft: isMobile ? "10px" : "30px",
        }}
        variant="contained"
      >
        <Link
          to={`/galerije`}
          style={{
            textDecoration: "none",
            color: "black",
            fontWeight: "bold",
          }}
        >
          <Stack
            direction="row"
            display="flex"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <WestIcon />
            <Typography>Nazaj</Typography>
          </Stack>
        </Link>
      </Button>
      {title && (
        <Box
          marginY={"20px"}
          sx={{ marginY: "20px" }}
          display={"flex"}
          justifyContent={"center"}
        >
          <Typography variant="h4">{title}</Typography>
        </Box>
      )}
      {images && (
        <Carousel
          showThumbs={images.length > 0}
          showIndicators={images.length > 0}
          swipeable={true}
          showArrows={true}
          onChange={() => {}}
          onClickItem={() => {}}
          onClickThumb={() => {}}
        >
          {images.map((image) => (
            <Box
              key={image.id}
              sx={{
                height: isMobile ? "60vh" : "80vh",
                aspectRatio: "auto",
                maxWidth: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={image.image || image.mainImage}
                alt="Real Estate"
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
              <p
                className="legend"
                style={{ backgroundColor: "rgba(0,0,0,0)", fontSize: "20px" }}
              >
                {image.description}
              </p>
            </Box>
          ))}
        </Carousel>
      )}
      {user && (
        <>
          <UpdateGalleryModal
            id={galleryId}
            galleryImages={images}
            title={title}
            onChange={get_images}
          />
          <Button
            variant="contained"
            color="error"
            fullWidth={true}
            onClick={() => delete_gallery()}
          >
            Izbriši Galerijo
          </Button>
        </>
      )}
    </Box>
  );
}

export default GalleryPage;
