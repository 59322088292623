import "./App.css";
import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthPrivider } from "./contex/AuthContex";

import HomePage from "./pages/HomePage";
import CreateArticlePage from "./pages/CreateArticlePage";
import GalleryPage from "./pages/GalleryPage";
import GalleriesPage from "./pages/GalleriesPage";
import ForumPage from "./pages/ForumPage";
import ArticleDetailPage from "./pages/ArticleDetailPage";
import UpdateArticlePage from "./pages/UpdateArticlePage";
import ProfilePage from "./pages/ProfilePage";

import MainNavbar from "./components/MainNavbar";
import Footer from "./components/Footer";
import SettingsPage from "./pages/SettingsPage";
import AscendsPage from "./pages/AscendsPage";
import NewsPage from "./pages/NewsPage";
import OdsekPage from "./pages/OdsekPage";
import SchoolPage from "./pages/SchoolPage";

function App() {
  const appStyles = {
    backgroundColor: "#c7c5c5",
    minHeight: "100vh",
  };
  return (
    <div className="App" style={appStyles}>
      <AuthPrivider>
        <Router>
          <Routes>
            <Route
              element={
                <React.Fragment>
                  <MainNavbar />
                  <Routes>
                    <Route element={<HomePage />} path="/*" />
                    <Route element={<AscendsPage />} path="/vzponi" />
                    <Route element={<NewsPage />} path="/novice" />
                    <Route
                      element={<GalleryPage />}
                      path="/galerije/:galleryId"
                    />
                    <Route element={<GalleriesPage />} path="/galerije" />
                    <Route element={<ForumPage />} path="/forum" />
                    <Route
                      element={<CreateArticlePage />}
                      path="/create_article"
                    />
                    <Route element={<ProfilePage />} path="/profile/:userId" />
                    <Route
                      element={<SettingsPage />}
                      path="/settings/:userId"
                    />
                    <Route element={<OdsekPage />} path="/odsek" />
                    <Route element={<SchoolPage />} path="/alpinistična_šola" />
                    <Route
                      element={<ArticleDetailPage />}
                      path="/article/:article_id"
                    />
                    <Route
                      element={<UpdateArticlePage />}
                      path="/article/update/:article_id"
                    />
                  </Routes>
                  <Footer />
                </React.Fragment>
              }
              path="*"
            />
          </Routes>
        </Router>
      </AuthPrivider>
    </div>
  );
}

export default App;
