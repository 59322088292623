function DateFormater(created) {
    const months = ['Januar', 'Februar', 'Marec', 'April', 'Maj', 'Junij', 'Julij', 'August', 'September', 'Oktober', 'November', 'December']
    const date = new Date(created)
    
    const year = date.getFullYear( )
    let month = months[date.getMonth()]
    const day = date.getDate( )

    return '' + month + " " + (day <= 9 ? '0' + day : day) + ', ' +  year
}

export default DateFormater
