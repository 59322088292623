import React from "react";
import { Link } from "react-router-dom";

import { Grid, Typography } from "@mui/material";

import backgroundImage from "../assets/default_image.jpg";
import Box from "@mui/material/Box";
import { Image } from "mui-image";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import DateFormater from "../utils/DateFunctions";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import EastIcon from "@mui/icons-material/East";
import { limitBody } from "../utils/utils";

function ArticleImage({ image }) {
  //TODO: Change default image
  return (
    <Box
      sx={{
        height: "350px",
        aspectRatio: "auto",
        maxWidth: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={image ?? backgroundImage}
        alt="Real Estate"
        style={{
          maxHeight: "100%",
          maxWidth: "100%",
          objectFit: "contain",
          boxShadow: "1px 2px 9px #1f4154",
        }}
      />
    </Box>
  );
}

function ArticleBody({
  title,
  body,
  created,
  author,
  gallery,
  type,
  article_id,
}) {
  const isMobile = window.innerWidth < 800;
  const theme = createTheme({
    typography: {
      title: {
        fontWeight: 500,
        fontSize: isMobile ? 22 : 30,
        fontStyle: "Josefin Sans",
        color: "#000",
      },
      description: {
        fontWeight: 300,
        fontSize: isMobile ? 16 : 18,
        fontStyle: "Josefin Sans",
        color: "#000",
      },
      body: {
        fontWeight: 500,
        fontSize: isMobile ? 16 : 30,
        fontStyle: "Josefin Sans",
        color: "#000",
      },
    },
  });
  return (
    <Stack>
      <ThemeProvider theme={theme}>
        <Typography variant="title">{title}</Typography>
        <Stack
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="center"
          divider={<Divider orientation="vertical" flexItem color={"black"} />}
          spacing={2}
          sx={{ paddingY: isMobile ? "16px" : "12px", }}
        >
          <Typography variant="description">{created}</Typography>
          <Typography variant="description" >{author}</Typography>
        </Stack>
        <Typography
          variant="descriptionBox"
          dangerouslySetInnerHTML={{ __html: limitBody(body) }}
        ></Typography>
        <Box sx={{ justifyContent: "center" }}>
          <Button sx={{ color: "#FFF", width: "200px", borderRadius: "30px", backgroundColor: "#bfd630", textTransform: 'none', }} variant="contained">
            <Link
              to={`/article/${article_id}`}
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bold",
              }}
            >
              <Stack
                direction="row"
                display="flex"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Typography>Preberi več</Typography>
                <EastIcon />
              </Stack>
            </Link>
          </Button>
        </Box>
      </ThemeProvider>
    </Stack>
  );
}

function Article({
  title,
  body,
  created,
  author,
  titleImage,
  gallery,
  type,
  article_id,
  index,
}) {
  const isMobile = window.innerWidth < 800;
  return (
    <Grid container spacing={isMobile ? 3 : 0} paddingX={isMobile ? "20px" : "0px"}> 
      {index % 2 == 0 || isMobile ? (
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ArticleImage image={titleImage} />
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          md={6}
          textAlign={"center"}
          paddingX={isMobile ? "20px" : "80px"}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
        >
          <ArticleBody
            title={title}
            body={body}
            created={created}
            author={author}
            gallery={gallery}
            type={type}
            article_id={article_id}
          />
        </Grid>
      )}
      {index % 2 == 0 || isMobile ? (
        <Grid
          item
          xs={12}
          md={6}
          textAlign={"center"}
          paddingX={isMobile ? "20px" : "80px"}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
        >
          <ArticleBody
            title={title}
            body={body}
            created={created}
            author={author}
            gallery={gallery}
            type={type}
            article_id={article_id}
          />
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ArticleImage image={titleImage} />
        </Grid>
      )}
    </Grid>
  );
}

export default Article;
