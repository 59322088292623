import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../contex/AuthContex";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";

import { Box, Grid, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";

import defaultImage from "../assets/no_profile.jpg";

function SettingsPage() {
  const [profileImage, setProfileImage] = useState(null);
  const [addImage, setAddImage] = useState(null);
  const [changeName, setChangeName] = useState(null);

  const { userId } = useParams();
  let { authTokens, user } = useContext(AuthContext);

  useEffect(() => {
    get_profile();
    window.scrollTo(0, 0);
  }, []);

  const get_profile = async () => {
    let response = await fetch(
      `https://api.ao-litija.si/api/profile/${userId}?userId=${userId}`
    );
    let data = await response.json();
    if (data.image !== null) {
      setProfileImage(data);
    } else {
      setProfileImage(null);
    }
  };

  const delete_profile = async () => {
    let response = await fetch(
      `https://api.ao-litija.si/api/profile/${userId}?userId=${userId}`,
      {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    );
    setProfileImage(null);
  };

  const create_profile = async () => {
    var formData = new FormData();
    formData.append("image", addImage);
    let response = await fetch(`https://api.ao-litija.si/api/profile`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: formData,
    });
    get_profile();
    setAddImage(null);
  };

  const update_name = async () => {
    const response = await fetch(`https://api.ao-litija.si/api/profile/name/${userId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({ first_name: changeName }),
    });
  };


  const theme = createTheme({
    typography: {
      title: {
        fontWeight: 400,
        fontSize: 50,
        fontWeight: 200,
        fontStyle: "Open Sans",
        color: "#000",
      },
    },
  });
  return (
    <Box marginTop={"40px"} sx={{ minHeight: "88vh" }}>
      <ThemeProvider theme={theme}>
        <Grid container alignItems={"center"} gap={1}>
          <Grid item xs={6}>
            <Typography>Spremeni Sliko Profila:</Typography>
          </Grid>
          <Grid item xs={12}>
            {profileImage ? (
              <div>
                <Card sx={{ width: 290 }}>
                  <CardMedia
                    sx={{ height: 300 }}
                    image={profileImage.image}
                    title="green iguana"
                  />
                </Card>
                <Button
                  sx={{ marginLeft: "20px", marginTop: "5px" }}
                  color="error"
                  variant="outlined"
                  onClick={delete_profile}
                >
                  Odstrani sliko
                </Button>
              </div>
            ) : (
              <div>
                <Card sx={{ width: 290 }}>
                  <CardMedia
                    sx={{ height: 300 }}
                    image={
                      addImage !== null
                        ? URL.createObjectURL(addImage)
                        : defaultImage
                    }
                    title="green iguana"
                  />
                </Card>
                <Input
                  accept="image/*"
                  id="image-input"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(event) => (
                    setAddImage(event.target.files[0])
                  )}
                />
                {addImage !== null ? (
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    onClick={create_profile}
                  >
                    Naloži Naslovno sliko
                  </Button>
                ) : (
                  <label htmlFor="image-input">
                    <Button
                      variant="contained"
                      component="span"
                      startIcon={<CloudUploadIcon />}
                    >
                      Izberi sliko
                    </Button>
                  </label>
                )}
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container alignItems={"center"} gap={1}>
          <Grid item xs={6}>
            <Typography>Spremeni Ime:</Typography>
          </Grid>
          <Grid item xs={12} display={"flex"} alignItems={"center"}>
            <TextField
              size="small"
              defaultValue={user.firstName}
              onChange={(e) => setChangeName(e.target.value)}
            />
            <Button
              variant="contained"
              component="span"
              onClick={update_name}
            >
              Spremeni Ime
            </Button>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Box>
  );
}

export default SettingsPage;
