import React, { useState, useContext } from "react";
import AuthContext from "../contex/AuthContex";

import Comment from "./Comment";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";

import DateFormater from "../utils/DateFunctions";

import { Stack } from "@mui/material";

function CommentsOffCanvas({ forum_post_id, num_comments }) {
  const [show, setShow] = useState(false);

  let [comments, setComments] = useState([]);
  let [createComment, setCreateComment] = useState({
    forum_post_id: forum_post_id,
    body: "",
  });

  let { authTokens } = useContext(AuthContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isMobile = window.innerWidth < 800;

  const get_comments = async () => {
    let response = await fetch(
      `https://api.ao-litija.si/api/comments?forum_post_id=${forum_post_id}`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    );
    let data = await response.json();
    setComments(data);
  };

  const create_Comment = async () => {
    await fetch(`https://api.ao-litija.si/api/comments`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify(createComment),
    });
    get_comments();
    setCreateComment({ ...createComment, body: "" });
  };

  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (open) {
      get_comments();
    }

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <React.Fragment key={"left"}>
      <IconButton
        aria-label="add to favorites"
        onClick={toggleDrawer("left", true)}
      >
        <CommentIcon />
      </IconButton>
      <Button onClick={toggleDrawer("left", true)} variant="text" sx={{color: "#000"}}>
        Komentarji ({num_comments})
      </Button>
      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        <Box
          sx={{
            width: isMobile ? "320px" : "400px",
            paddingY: "50px",
            backgroundColor: "#e6e3e3",
          }}
          role="presentation"
          height={"100%"}
        >
          <Stack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"space-between"}
            height={"100%"}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                overflow: "hidden",
                overflowY: "scroll",
                paddingX: "20px",
              }}
            >
              <Stack gap={2}>
                {comments.map((comment) => (
                  <Comment
                    key={comment.id}
                    comment={comment}
                    onDelete={get_comments}
                  />
                ))}
              </Stack>
            </Box>
            <Stack direction={"row"} marginTop={"20px"}>
              <TextField
                fullWidth
                value={createComment.body}
                label="Napiši komentar.."
                size="small"
                type="text"
                InputProps={{
                  sx: {
                    borderRadius: 10,
                    marginRight: "30px",
                    backgroundColor: "white",
                  },
                }}
                onChange={(e) =>
                  setCreateComment({ ...createComment, body: e.target.value })
                }
              />
              <IconButton
                aria-label="add to favorites"
                color={createComment.body.length < 1 ? "disabled" : "primary"}
                onClick={createComment.body.length < 1 ? null : create_Comment}
              >
                <SendIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

export default CommentsOffCanvas;
